body{
    margin: 0%;
  
} 
.container1{
    height: 500px;
    width: 100%;
    margin-top: 90px;
   
    padding: 0%;
    box-sizing: border-box;
    background-image: url("https://res.cloudinary.com/ddkyeuhk8/image/upload/v1684916669/IMG-20230521-WA0001_fpeyfo.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative; /* Add this line */
    white-space: normal;
}
@media only screen and(max-width: 750px) {
    .container1{ 
        height: 450px;
    }
}

/* hero 1 css---------- */
.hero11{
    height: 300px;
    width: 100%;
 
    box-sizing: border-box;
    /* padding: 18px; */
    text-align: justify;
    white-space: normal;
}
.hero111{
  height: 300px;
  width: 100%;

  box-sizing: border-box;
  padding: 18px;
  text-align: justify;
  white-space: normal;
}
.hero11 h1 {
    font-weight: 800;
}
.button{
    display: flex;
    justify-content: center;
}      
.btn-grad {
    width: 280px;
    background-image: linear-gradient(to right, #DD5E89 0%, #F7BB97  51%, #DD5E89  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  .car{
   
 white-space: normal;
    
  }
  .car11{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
   
    width: 100%;
    
  }
  .card44{
    width: 15rem;
    margin: 20px;
  }

  @media only screen and (max-width: 767px) {
    .hero11{
        height: 320px;
    }
    .card44{
      width: 10rem;
      margin: 20px;
    }
    .container1{ 
        height: 200px;
    }
    .btn-grad {
        margin: 5px;
    padding: 10px 5px;
    }
    .card44{
        width: 20rem;
        margin: 20px;
      }
  }


  /* //Image section------------ */
  .first1{
    margin: 0;
    padding: 0;
    text-align: left justify;
    
    
  }
  .textg{
    text-align: justify;
    margin: 20px;
    padding: 20px;
    padding-top: 0%;
    padding-bottom: 0px;
  }
  .first2{
    display:flex ;
    flex-wrap: wrap;
    justify-content: center;
    
  }
  .imgc{
    height: 350px;
    width: 350px;
    -webkit-box-shadow: 0px 2px 10px 9px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 2px 10px 9px rgba(0,0,0,0.24);
    box-shadow: 0px 2px 10px 9px rgba(0,0,0,0.24);
    margin: 20px;
    box-sizing: border-box;
    border-radius: 17px;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .imgc h5{
   
    position: absolute;
    bottom: 8px;
    left: 16px;
   font-weight: 800;
   color: rgb(255, 255, 255);
  }
  .imgc img{
    align-items: center;
    height: 350px;
    width: 348px;
    border-radius: 17px;
    transition: 3.5s all ease-in-out;
    

  }
  .imgc img:hover{
    transform: scale(1.5);
  }
 
   @media screen and (max-width: 750px) {
   .textg{
    margin: 20px;
    color: #DD5E89;
   }
   .imgc{
    height: 260px;
    width: 260px;}
    .imgc img{
      align-items: center;
      height: 300px;
      width: 298px;}
      .hg{
        text-align: justify;
      }
      .pg{
        text-align: justify;
      }
      .hk{
        text-align: justify;
      }
}
.hg{
  background: #FFB76B;
  background: linear-gradient(to right, #FFB76B 0%, #FFA73D 30%, #FF7C00 60%, #FF7F04 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
text-align: center;
}
.pg{
  text-align: center ;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 19px;
}
.hk{
  padding-top: 20px;
  text-align: center;
  background: #FFB76B;
  background: linear-gradient(to right, #FFB76B 0%, #FFA73D 30%, #FF7C00 60%, #FF7F04 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  

}
