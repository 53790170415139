.mainbook{
    padding-top: 120px;
    height: 100vh;
    display: flex;
    justify-content: center;
    background: rgb(251,236,63);
background: linear-gradient(14deg, rgba(251,236,63,1) 0%, rgba(55,0,182,1) 100%);
}
.book{
    width: 80%;
}
@media screen and (max-width: 750px) {
    .book{
        width: 100%;
    } 
}