.faq1{
    background-image: url("https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685514478/landscape-2149844_1280_pjiwty.jpg" );
    
    background-repeat: no-repeat;
    background-size: cover;
    height: 420px;
    background-position: center;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 80px;
    /* opacity: 0.5; */
    }
    .faq1 h1{
        font-size: 90px;
        color: rgb(238, 255, 0);
        opacity: 1;
        text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
    }
    .faq1 p{
        font-size: 30px;
        color: rgb(207, 10, 191);
        font-weight: 900;
        text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
        font-family: 'Times New Roman', Times, serif;
    }
    @media screen and (max-width: 750px) {
        .faq1{
            height: 220px;
        }
        .faq1 h1{
            font-size: 40px;
            color: aqua;
        }
        .faq1 p{
            font-size: 15px;
            color: rgb(14, 221, 7);
            font-weight: 900;
            text-shadow: black;
        }
    }
    .faqtxt{
        display: flex;
        flex-wrap: wrap;
        text-align: justify;
       
        margin: 20px;
    }
    .faqtxt p{
        font-family: 'Times New Roman', Times, serif;
        font-size: 19px;
        color: rgb(104, 88, 88);
    }