.mainfooter{
    height: auto;
    width: 100%;
   padding: 10px;
    display: flex;
    flex-wrap: wrap;
 justify-content: center;
 background: rgb(0,0,0);
 background: linear-gradient(357deg, rgba(0,0,0,1) 0%, rgba(0,182,110,1) 0%);
    -webkit-box-shadow: 2px -5px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 2px -5px 5px 0px rgba(0,0,0,0.75);
box-shadow: 2px -5px 5px 0px rgba(0,0,0,0.75);
 
    
}

.textsec{
/* background-color: rgb(0, 0, 0); */
height: auto;
width: 350px;
margin: 5px;
 
position: relative;
text-align: center justify;


}
.textsec img{
    height: 100px;
    width: 160px;
    
}

.contiom h4{
    
    text-decoration: none;
    color: aliceblue;
}
.tom{
    color: aqua;
    text-decoration: none!important;
    cursor: pointer;
font-size: 15px;
color: black;
}



@media screen and (max-width: 750px) {
    .textsec{
        text-align: justify;
    }
}
 a{
    text-decoration: none;
    cursor: pointer;
}
.a4a a{
    color: brown;
}
