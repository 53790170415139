.wat{
    height: 50px;
    width: 100%;
   
    display: flex;
    justify-content: space-between;
    position: fixed;
  bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
  z-index: 12;
  
}
.wat img {
    animation: shake 5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
    margin-right: 15px;
        margin-left: 15px;
        height: 60px;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  .wat a{
    color: black;
    text-decoration: none;
    font-size: 20px;
    animation: shake 5s;
        color: rgb(1, 0, 59);
    font-weight: 900;
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
  }
@media screen and (max-width: 750px) {
    .wat img{
        height: 40px;
        width: 50px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .wat a{
        font-size: 10px;
        font-weight: 900;
        color: rgb(11, 9, 139);
    }
}