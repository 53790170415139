.mainabout{
    padding-top: 50px;
    height: auto;
    padding-bottom: 20px;
    text-align: justify ;
    display: flex;
    flex-wrap: wrap;
    margin: 0px 40px 0 40px;
}
.faqq1{
    background-image: url("https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685512367/sky-4917936_640_s5kl9w.jpg" );
    
    background-repeat: no-repeat;
    background-size: cover;
    height: 420px;
    background-position: center;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 90px;
    /* opacity: 0.5; */
    }
    .faqq1 h1{
        font-size: 90px;
        color: rgb(238, 255, 0);
        opacity: 1;
        text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
    }
    .faqq1 p{
        font-size: 30px;
        color: rgb(14, 230, 212);
        font-weight: 900;
        text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
    }
    @media screen and (max-width: 750px) {
        .faqq1{
            height: 220px;
        }
        .faqq1 h1{
            font-size: 40px;
            color: aqua;
        }
        .faqq1 p{
            font-size: 15px;
            color: rgb(14, 221, 7);
            font-weight: 900;
            text-shadow: black;
        }
    }