.maincont{
padding-top: 90px;
padding-bottom: 20px;
margin: 0;
}
.main2{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    width: 100%;
    padding-top: 20px;
}
.mainimage{
    height: 260px;
    width: 494px;
    /* border: 2px solid black; */
    margin: 5px;
    border-radius: 7px;
}
.mainimage img{
    height: 256px;
    width: 490px;
    border-radius: 7px;
   
}
@media screen and (max-width: 750px) {
    .mainimage{
        height: 200px;
        width: 334px;
       
        margin: 5px;
    }
    .mainimage img{
        height: 200px;
        width: 330px;
       
    }
}
.bgimg{
    background-image: url("https://res.cloudinary.com/ddkyeuhk8/image/upload/v1685514547/lake-7039109_640_gvb4rv.jpg" );
    
    background-repeat: no-repeat;
    background-size: cover;
    height: 420px;
    background-position: center;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 120px; */
    /* opacity: 0.5; */
    }
    .bgimg h1{
        font-size: 90px;
        color: rgb(238, 255, 0);
        opacity: 1;
        text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
    }
    .bgimg p{
        font-size: 30px;
        color: rgb(14, 230, 212);
        font-weight: 900;
        text-shadow: 4px 4px 2px rgba(0,0,0,0.6);
    }
    @media screen and (max-width: 750px) {
        .bgimg{
            height: 220px;
        }
        .bgimg h1{
            font-size: 40px;
            color: aqua;
        }
        .bgimg p{
            font-size: 15px;
            color: rgb(14, 221, 7);
            font-weight: 900;
            text-shadow: black;
        }
    }
