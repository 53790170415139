.Nav{
    height: 90px;
    background: rgb(0,0,0);
    background: linear-gradient(357deg, rgba(0,0,0,1) 0%, rgb(182, 109, 0) 0%);
font-size: 20px;
font-weight: 700;
color: white;
box-sizing: border-box;

}
.image img{
height: 80px;
width: auto;
}
#mm{
    background:  rgb(182, 109, 0);
}
