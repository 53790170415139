.newhero{
    margin: 20px;
    justify-content: center;
    text-align: justify;
}
.newcard{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    width: 100%;
}